<template>
  <svg fill="none" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M14.4,20.1c-0.3,0-0.7-0.2-0.8-0.6L9.6,7.3l-1.7,5c-0.1,0.3-0.4,0.6-0.8,0.6H3.9c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8
		h2.7l2.2-6.7c0.1-0.3,0.4-0.6,0.8-0.6s0.7,0.2,0.8,0.6l4.1,12.3l1.7-5c0.1-0.3,0.4-0.6,0.8-0.6h3.2c0.4,0,0.8,0.4,0.8,0.8
		s-0.4,0.8-0.8,0.8h-2.7l-2.2,6.7C15.1,19.9,14.8,20.1,14.4,20.1z"
    />
  </svg>
</template>
