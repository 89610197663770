<template>
  <svg fill="none" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M4.75 6.75L9.25 4.75V17.25L4.75 19.25V6.75Z"
    ></path>
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.75 6.75L19.25 4.75V17.25L14.75 19.25V6.75Z"
    ></path>
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.75 6.75L9.25 4.75V17.25L14.75 19.25V6.75Z"
    ></path>
  </svg>
</template>
